/**
 * @generated SignedSource<<9552cb48b820d80e255b18ad07a51174>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssetTokenStandard = "AUCTION_HOUSE" | "BITKEEP" | "BLUR" | "CONSIDERATION" | "CRYPTOKITTIES" | "CRYPTOPUNKS" | "CUSTOM" | "DECENTRALAND" | "ELEMENT" | "ENJIN_LEGACY" | "ERC1155" | "ERC20" | "ERC721" | "ETH_BRIDGE" | "JOEPEGS" | "KNOWNORIGIN" | "LOOKSRARE" | "MAKERSPLACE" | "METAPLEX" | "SEADROP" | "SOLANA" | "SPL" | "SUPERRARE" | "TOFU" | "TRANSFER_HELPER" | "TROVE" | "UNKNOWN" | "WYVERN" | "X2Y2" | "ZERO_EX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AssetEventDealCell_swap$data = {
  readonly makerAssetQuantities: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly asset: {
          readonly collection: {
            readonly isVerified: boolean;
            readonly name: string;
          };
          readonly decimals: number | null;
          readonly displayName: string;
          readonly relayId: string;
          readonly symbol: string | null;
          readonly tokenStandard: AssetTokenStandard | null;
          readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset">;
        };
        readonly quantity: string;
      } | null;
    } | null>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DealLink_swap">;
  readonly " $fragmentType": "AssetEventDealCell_swap";
};
export type AssetEventDealCell_swap$key = {
  readonly " $data"?: AssetEventDealCell_swap$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetEventDealCell_swap">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetEventDealCell_swap",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 32
        }
      ],
      "concreteType": "AssetQuantityTypeConnection",
      "kind": "LinkedField",
      "name": "makerAssetQuantities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetQuantityTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetQuantityType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetType",
                  "kind": "LinkedField",
                  "name": "asset",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "relayId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CollectionType",
                      "kind": "LinkedField",
                      "name": "collection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isVerified",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "decimals",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "symbol",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "tokenStandard",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AssetMedia_asset"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "makerAssetQuantities(first:32)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DealLink_swap"
    }
  ],
  "type": "SwapType",
  "abstractKey": null
};

(node as any).hash = "ef195f8784b7258c64995e1f819be6e8";

export default node;
