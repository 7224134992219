import { Sort } from "@/components/search/assets/AssetSearchSortDropdown.react"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { AssetEventEventType } from "@/lib/graphql/__generated__/AssetEventType.graphql"
import { SearchSortBy } from "@/lib/graphql/__generated__/DealAssetSearchListQuery.graphql"

export const NAVBAR_AND_FOOTER_HEIGHT = "72px"

// Limit to 30 to avoid needing to paginate to load items in DealReview.
export const DEALS_MAX_ASSETS_PER_SIDE = 30

export const DEALS_CARD_VARIANT: AssetCardVariant = "cozy"

export const DEALS_ASSET_SEARCH_SORTS: Sort[] = [
  { sortAscending: false, sortBy: "LAST_TRANSFER_DATE" },
  { sortAscending: false, sortBy: "UNIT_PRICE" },
  { sortAscending: true, sortBy: "UNIT_PRICE" },
  { sortAscending: false, sortBy: "FLOOR_PRICE" },
  { sortAscending: false, sortBy: "BEST_BID" },
  { sortAscending: false, sortBy: "LISTING_DATE" },
  { sortAscending: false, sortBy: "CREATED_DATE" },
  { sortAscending: false, sortBy: "LAST_SALE_PRICE" },
  { sortAscending: true, sortBy: "CREATED_DATE" },
]

export const DEALS_DEFAULT_SORT_BY: SearchSortBy = "LAST_TRANSFER_DATE"

const DEAL_ASSET_EVENT_TYPES: AssetEventEventType[] = [
  "SWAP_CREATED",
  "SWAP_ACCEPTED",
  "SWAP_CANCELLED",
]

export type DealAssetEventEventType = (typeof DEAL_ASSET_EVENT_TYPES)[number]

export const isDealEventType = (eventType: AssetEventEventType) =>
  DEAL_ASSET_EVENT_TYPES.includes(eventType)
