/**
 * @generated SignedSource<<64e69fb59a2be470279db448acc6f744>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AssetEventEventType = "APPROVE" | "BID_ENTERED" | "BID_WITHDRAWN" | "BULK_CANCEL" | "CANCELLED" | "COLLECTION_OFFER" | "CREATED" | "CUSTOM" | "OFFER_ENTERED" | "PAYOUT" | "REDEMPTION" | "SUCCESSFUL" | "SWAP_ACCEPTED" | "SWAP_CANCELLED" | "SWAP_CREATED" | "TRAIT_OFFER" | "TRANSFER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type utilsAssetEventLabel$data = {
  readonly eventType: AssetEventEventType;
  readonly isAirdrop: boolean;
  readonly isMint: boolean;
  readonly " $fragmentType": "utilsAssetEventLabel";
};
export type utilsAssetEventLabel$key = {
  readonly " $data"?: utilsAssetEventLabel$data;
  readonly " $fragmentSpreads": FragmentRefs<"utilsAssetEventLabel">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "utilsAssetEventLabel"
};

(node as any).hash = "f08402d98343456ce9e93cc036065612";

export default node;
