/**
 * @generated SignedSource<<4891b3ba0864eb5ab805fed570c8ecd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssetEventEventType = "APPROVE" | "BID_ENTERED" | "BID_WITHDRAWN" | "BULK_CANCEL" | "CANCELLED" | "COLLECTION_OFFER" | "CREATED" | "CUSTOM" | "OFFER_ENTERED" | "PAYOUT" | "REDEMPTION" | "SUCCESSFUL" | "SWAP_ACCEPTED" | "SWAP_CANCELLED" | "SWAP_CREATED" | "TRAIT_OFFER" | "TRANSFER" | "%future added value";
export type AssetEventOrderStatus = "ACCEPTED" | "BOUGHT" | "CANCELLED" | "EXPIRED" | "%future added value";
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventHistory_data$data = ReadonlyArray<{
  readonly collection: {
    readonly " $fragmentSpreads": FragmentRefs<"CollectionCell_collection">;
  } | null;
  readonly customEventName: string | null;
  readonly endingPriceType: {
    readonly unit: string;
  } | null;
  readonly eventTimestamp: string;
  readonly eventType: AssetEventEventType;
  readonly fromAccount: {
    readonly address: string;
    readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
  } | null;
  readonly item?: {
    readonly __typename: string;
    readonly assetContract?: {
      readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_assetContract">;
    };
    readonly bundleCollection?: {
      readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
    } | null;
    readonly collection?: {
      readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
    };
    readonly defaultRarityData?: {
      readonly rank: number;
    } | null;
    readonly relayId: string;
    readonly verificationStatus: VerificationStatus;
    readonly " $fragmentSpreads": FragmentRefs<"ActivityTableFullRow_itemData" | "ItemCell_data" | "PortfolioTableItemCellTooltip_item" | "item_url">;
  } | null;
  readonly itemQuantity: string | null;
  readonly orderStatus: AssetEventOrderStatus | null;
  readonly payment: {
    readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
  } | null;
  readonly perUnitPrice: {
    readonly eth: string;
    readonly unit: string;
    readonly usd: string;
  } | null;
  readonly priceType: {
    readonly unit: string;
  } | null;
  readonly relayId: string;
  readonly sellOrder: {
    readonly taker: {
      readonly __typename: "AccountType";
    } | null;
  } | null;
  readonly seller: {
    readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
  } | null;
  readonly swap: {
    readonly __typename: "SwapType";
    readonly makerAssetQuantities: {
      readonly count: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly asset: {
            readonly __typename: "AssetType";
            readonly assetContract: {
              readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_assetContract">;
            };
            readonly collection: {
              readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
            };
            readonly defaultRarityData: {
              readonly rank: number;
            } | null;
            readonly relayId: string;
            readonly verificationStatus: VerificationStatus;
            readonly " $fragmentSpreads": FragmentRefs<"ActivityTableFullRow_itemData" | "ItemCell_data" | "PortfolioTableItemCellTooltip_item" | "item_url">;
          };
        } | null;
      } | null>;
    };
    readonly takerAssetQuantities: {
      readonly count: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AssetEventDealCell_swap" | "DealLink_swap">;
  } | null;
  readonly toAccount: {
    readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
  } | null;
  readonly traitCriteria: {
    readonly " $fragmentSpreads": FragmentRefs<"CollectionCell_trait">;
  } | null;
  readonly winnerAccount: {
    readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventTimestamp_data" | "utilsAssetEventLabel">;
  readonly " $fragmentType": "EventHistory_data";
}>;
export type EventHistory_data$key = ReadonlyArray<{
  readonly " $data"?: EventHistory_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventHistory_data">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verificationStatus",
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ItemCell_data"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  (v0/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "kind": "InlineDataFragmentSpread",
        "name": "asset_url",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetContractType",
            "kind": "LinkedField",
            "name": "assetContract",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tokenId",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "args": null,
        "argumentDefinitions": ([]/*: any*/)
      }
    ],
    "type": "AssetType",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "kind": "InlineDataFragmentSpread",
        "name": "bundle_url",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "args": null,
        "argumentDefinitions": ([]/*: any*/)
      }
    ],
    "type": "AssetBundleType",
    "abstractKey": null
  }
],
v7 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "PortfolioTableItemCellTooltip_item"
},
v8 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ActivityTableFullRow_itemData"
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "RarityDataType",
  "kind": "LinkedField",
  "name": "defaultRarityData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rank",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CollectionLink_collection"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionType",
  "kind": "LinkedField",
  "name": "collection",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetContractType",
  "kind": "LinkedField",
  "name": "assetContract",
  "plural": false,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionLink_assetContract"
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventType",
  "storageKey": null
},
v14 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "AccountLink_data"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v16 = [
  (v15/*: any*/)
],
v17 = [
  (v14/*: any*/)
],
v18 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 32
  }
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showAll"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EventHistory_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CollectionCell_collection"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TraitType",
      "kind": "LinkedField",
      "name": "traitCriteria",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CollectionCell_trait"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemQuantity",
      "storageKey": null
    },
    {
      "condition": "showAll",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "item_url",
              "selections": (v6/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v9/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/)
              ],
              "type": "AssetType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": "bundleCollection",
                  "args": null,
                  "concreteType": "CollectionType",
                  "kind": "LinkedField",
                  "name": "collection",
                  "plural": false,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "AssetBundleType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventTimestamp",
      "storageKey": null
    },
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customEventName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderStatus",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "utilsAssetEventLabel",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isMint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAirdrop",
          "storageKey": null
        },
        (v13/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "fromAccount",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v14/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPrice",
      "plural": false,
      "selections": [
        (v15/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usd",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "endingPriceType",
      "plural": false,
      "selections": (v16/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "priceType",
      "plural": false,
      "selections": (v16/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "TokenPricePayment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": (v17/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderV2Type",
      "kind": "LinkedField",
      "name": "sellOrder",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountType",
          "kind": "LinkedField",
          "name": "taker",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "toAccount",
      "plural": false,
      "selections": (v17/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "winnerAccount",
      "plural": false,
      "selections": (v17/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwapType",
      "kind": "LinkedField",
      "name": "swap",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": (v18/*: any*/),
          "concreteType": "AssetQuantityTypeConnection",
          "kind": "LinkedField",
          "name": "makerAssetQuantities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetQuantityTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetQuantityType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetType",
                      "kind": "LinkedField",
                      "name": "asset",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "item_url",
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": (v6/*: any*/),
                              "type": "ItemType",
                              "abstractKey": "__isItemType"
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        },
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        (v11/*: any*/),
                        (v12/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v19/*: any*/)
          ],
          "storageKey": "makerAssetQuantities(first:32)"
        },
        {
          "alias": null,
          "args": (v18/*: any*/),
          "concreteType": "AssetQuantityTypeConnection",
          "kind": "LinkedField",
          "name": "takerAssetQuantities",
          "plural": false,
          "selections": [
            (v19/*: any*/)
          ],
          "storageKey": "takerAssetQuantities(first:32)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetEventDealCell_swap"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DealLink_swap"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTimestamp_data"
    }
  ],
  "type": "AssetEventType",
  "abstractKey": null
};
})();

(node as any).hash = "6312292e113ad75a248d6921fc4fe5ac";

export default node;
