import React from "react"
import { Text, classNames } from "@opensea/ui-kit"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetEventOrderStatus as AssetEventOrderStatusType } from "@/lib/graphql/__generated__/EventHistory_data.graphql"

type Props = {
  orderStatus: AssetEventOrderStatusType
}

export const AssetEventOrderStatus = ({ orderStatus }: Props) => {
  const t = useTranslate("activity")
  let color = ""
  let text = ""

  switch (orderStatus) {
    case "ACCEPTED":
      color = "text-green-2"
      text = t("eventHistory.accepted", "Accepted")
      break
    case "BOUGHT":
      color = "text-green-2"
      text = t("eventHistory.sold", "Sold")
      break
    case "EXPIRED":
      color = "text-red-2"
      text = t("eventHistory.expired", "Expired")
      break
    case "CANCELLED":
      color = "text-red-2"
      text = t("eventHistory.canceled", "Canceled")
      break
    default:
      return null
  }

  return (
    <Text.Body
      className={classNames(color, "ml-2")}
      size="tiny"
      weight="semibold"
    >
      {text}
    </Text.Body>
  )
}
