import React from "react"
import styled, { css } from "styled-components"
import { media } from "@/styles/styleUtils"
import { mapChildren } from "../../lib/helpers/array"
import { selectClassNames } from "../../lib/helpers/styling"

type Props = {
  children: React.ReactNode
  className?: string
  isHeader?: boolean
  spaced?: boolean
  compactPadding?: boolean
  columnIndexClassName?: {
    [index: number]: string
  }
  bordered?: boolean
}

const Row = ({
  children,
  className,
  columnIndexClassName,
  isHeader,
  spaced,
  compactPadding,
  bordered = true,
}: Props) => (
  <DivContainer
    bordered={bordered}
    className={selectClassNames(
      "Row",
      {
        isHeader,
        compactPadding,
      },
      className,
    )}
    role="row"
  >
    {mapChildren(children, (child, index) =>
      child === undefined || child === null || child === false ? null : (
        <div
          className={selectClassNames(
            "Row",
            { cell: true, cellIsSpaced: spaced },
            columnIndexClassName?.[index],
          )}
        >
          {child}
        </div>
      ),
    )}
  </DivContainer>
)
export default Row

const DivContainer = styled.div<{ bordered: boolean }>`
  display: flex;

  &:last-child {
    border-bottom: none;
  }
  border-bottom: ${props =>
    props.bordered
      ? `1px solid ${props.theme.colors.components.border.level2}`
      : ""};

  .Row--cell {
    align-items: center;
    display: flex;
    flex: 1 0 100px;
    overflow-x: auto;
    padding: 16px 4px;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }

  &.Row--compactPadding {
    .Row--cell {
      padding: 4px 0;
    }
  }

  &.Row--isHeader {
    position: sticky;
    top: 0;
    z-index: 1;

    .Row--cell {
      color: ${props => props.theme.colors.text.primary};
      padding-bottom: 4px;
      padding-top: 4px;
    }
  }

  .Row--cellIsSpaced {
    flex-basis: 150px;
  }

  ${media({
    sm: css`
      .Row--cellIsSpaced {
        flex-basis: 100px;
      }
    `,
  })}
`
