import React from "react"
import { TextBodyProps, Text, classNames } from "@opensea/ui-kit"
import { DealAssetEventEventType } from "@/features/deals/constants"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  eventType: DealAssetEventEventType
} & Pick<TextBodyProps, "size" | "weight">

export const AssetEventDealStatus = ({ eventType, size, weight }: Props) => {
  const t = useTranslate("activity")
  let color = ""
  let text = ""

  switch (eventType) {
    case "SWAP_ACCEPTED":
      color = "text-green-2"
      text = t("eventHistory.swap.accepted", "Accepted")
      break
    case "SWAP_CANCELLED":
      color = "text-red-2"
      text = t("eventHistory.swap.canceled", "Canceled")
      break
    case "SWAP_CREATED":
      color = "text-secondary"
      text = t("eventHistory.swap.sent", "Sent")
      break
    default:
      return null
  }

  return (
    <Text.Body
      className={classNames(color, "ml-1 lg:ml-2")}
      size={size}
      weight={weight}
    >
      {text}
    </Text.Body>
  )
}
