import React from "react"
import { Media, Text, classNames } from "@opensea/ui-kit"
import styled from "styled-components"
import { FeatureTable } from "@/design-system/FeatureTable"
import { useTranslate } from "@/hooks/useTranslate"
import { IActivityTableCellWidths } from "./ActivityTable"

type Props = {
  cellWidths: IActivityTableCellWidths
}

export const ActivityTableHeader = ({ cellWidths }: Props) => {
  const t = useTranslate("activity")
  const eventLabelHeader = (
    <FeatureTable.Cell
      width={cellWidths.eventLabelCellWidth}
    ></FeatureTable.Cell>
  )
  const itemHeader = (
    <FeatureTable.Cell justifyContent="start" width={cellWidths.itemCellWidth}>
      <HeaderText>{t("header.item", "Item")}</HeaderText>
    </FeatureTable.Cell>
  )
  const priceHeader = (
    <FeatureTable.Cell justifyContent="end" width={cellWidths.priceCellWidth}>
      <HeaderText>{t("header.price", "Price")}</HeaderText>
    </FeatureTable.Cell>
  )
  const rarityHeader = (
    <FeatureTable.Cell justifyContent="end" width={cellWidths.rarityCellWidth}>
      <HeaderText>{t("header.rarity", "Rarity")}</HeaderText>
    </FeatureTable.Cell>
  )

  const quantityHeader = (
    <FeatureTable.Cell
      justifyContent="end"
      width={cellWidths.quantityCellWidth}
    >
      <HeaderText>{t("header.quantity", "Quantity")}</HeaderText>
    </FeatureTable.Cell>
  )
  const fromHeader = (
    <FeatureTable.Cell justifyContent="end" width={cellWidths.fromCellWidth}>
      <HeaderText>{t("header.from", "From")}</HeaderText>
    </FeatureTable.Cell>
  )
  const toHeader = (
    <FeatureTable.Cell justifyContent="end" width={cellWidths.toCellWidth}>
      <HeaderText>{t("header.to", "To")}</HeaderText>
    </FeatureTable.Cell>
  )
  const timeHeader = (
    <FeatureTable.Cell
      justifyContent="end"
      width={cellWidths.timestampCellWidth}
    >
      <HeaderText>{t("header.time", "Time")}</HeaderText>
    </FeatureTable.Cell>
  )

  return (
    <Media greaterThanOrEqual="lg">
      {mediaClassNames => (
        <FeatureTable.HeaderContainer
          className={classNames("z-[3]", mediaClassNames)}
        >
          {eventLabelHeader}
          {itemHeader}
          {priceHeader}
          {rarityHeader}
          {quantityHeader}
          {fromHeader}
          {toHeader}
          {timeHeader}
        </FeatureTable.HeaderContainer>
      )}
    </Media>
  )
}

const HeaderText = styled(Text.Body).attrs({
  size: "small",
})`
  color: ${props => props.theme.colors.text.secondary};
`
