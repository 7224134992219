/* eslint-disable tailwindcss/no-custom-classname */
import React, { useMemo, useRef, useState } from "react"
import {
  Icon,
  UnstyledButton,
  Spinner,
  VerticalAligned,
  CenterAligned,
  Flex,
} from "@opensea/ui-kit"
import clsx from "clsx"
import localforage from "localforage"
import { first, noop } from "lodash"
import {
  LoadMoreFn,
  RefetchFnDynamic,
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
} from "react-relay"
import { useUpdateEffect } from "react-use"
import { OperationType } from "relay-runtime"
import styled, { css } from "styled-components"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { ItemCell } from "@/components/assets/ItemCell"
import { LAST_CLICKED_BEST_ASK_DATA_KEY } from "@/components/assets/localstorage"
import {
  getTokenPricePayment,
  TokenPrice,
} from "@/components/assets/price/TokenPrice.react"
import { CollectionCell } from "@/components/collections/CollectionCell.react"
import Row from "@/components/common/Row.react"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { Panel } from "@/components/layout/Panel"
import Scrollbox from "@/components/layout/Scrollbox.react"
import { useEventFilters } from "@/components/search/featured-filter"
import { NO_HISTORY_DATA_IMG } from "@/constants"
import { Block } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import { Pill } from "@/design-system/Pill"
import {
  loadNextToLoadMore,
  ScrollingPaginator,
} from "@/design-system/ScrollingPaginator"
import { Select } from "@/design-system/Select"
import { Tooltip } from "@/design-system/Tooltip"
import { ActivityTable } from "@/features/activity"
import { getAssetEventLabel } from "@/features/activity/ActivityTable/utils"
import { AssetEventOrderStatus } from "@/features/activity/components/AssetEventOrderStatus.react"
import { useCopy } from "@/features/activity/constants"
import type { EventLabelData } from "@/features/activity/constants"
import { usePollingQuery } from "@/hooks/usePollingQuery"
import { useTranslate } from "@/hooks/useTranslate"
import type { EventHistory_data$key } from "@/lib/graphql/__generated__/EventHistory_data.graphql"
import {
  EventHistoryGeneral_data$data,
  EventHistoryGeneral_data$key,
} from "@/lib/graphql/__generated__/EventHistoryGeneral_data.graphql"
import { EventHistoryPaginationQuery } from "@/lib/graphql/__generated__/EventHistoryPaginationQuery.graphql"
import type { EventHistoryPollQuery } from "@/lib/graphql/__generated__/EventHistoryPollQuery.graphql"
import type {
  EventHistoryQuery,
  EventHistoryQuery$variables,
  EventType,
} from "@/lib/graphql/__generated__/EventHistoryQuery.graphql"
import { getNodes, graphql, Node, PageProps } from "@/lib/graphql/graphql"
import {
  MAX_DISPLAYED_DECIMAL_PLACES,
  bn,
  quantityDisplay,
} from "@/lib/helpers/numberUtils"
import { selectClassNames } from "@/lib/helpers/styling"
import { isSafelisted } from "@/lib/helpers/verification"
import { media, themeVariant } from "@/styles/styleUtils"
import { EventTimestamp } from "./EventTimestamp"

const PAGE_SIZE = 16

type Mode = "full" | "fungible" | "nonfungible" | "payouts" | "default"

export type Props = {
  className?: string
  mode?: Mode
  scrollboxClassName?: string
  pollingInterval?: number
  useWindow?: boolean
  showFilters?: boolean
  activityTableKey?: number
  showCollectionLinks?: boolean
  noPadding?: boolean
  excludedFilters?: EventType[]
  variables: EventHistoryQuery$variables
}

// This query should be identical to the EventHistory_data fragment and is a workaround until
//  we can get subscriptions working
const pollingQuery = graphql`
  query EventHistoryPollQuery(
    $archetype: ArchetypeInputType
    $categories: [CollectionSlug!]
    $chains: [ChainScalar!]
    $collections: [CollectionSlug!]
    $count: Int = 10
    $cursor: String
    $eventTimestamp_Gt: DateTime
    $eventTypes: [EventType!]
    $identity: IdentityInputType
    $showAll: Boolean = false
    $stringTraits: [TraitInputType!]
    $rarityFilter: RarityFilterType
  ) {
    eventActivity(
      after: $cursor
      archetype: $archetype
      categories: $categories
      chains: $chains
      collections: $collections
      eventTimestamp_Gt: $eventTimestamp_Gt
      eventTypes: $eventTypes
      first: $count
      identity: $identity
      includeHidden: true
      stringTraits: $stringTraits
      rarityFilter: $rarityFilter
    ) {
      edges {
        node {
          ...EventHistory_data @arguments(showAll: $showAll)
          eventTimestamp
        }
      }
    }
  }
`

export const EventHistory = (props: Props) => {
  const [eventTypeFilters, setEventTypeFilters] = useState<EventType[]>([])

  return (
    <SsrSuspense
      fallback={
        <EventHistoryBase
          data={[]}
          hasNext={false}
          isLoading
          isLoadingNext={false}
          loadNext={noop as LoadMoreFn<OperationType>}
          panelProps={{
            eventTypeFilters,
            setEventTypeFilters,
            refetch: noop as RefetchFnDynamic<OperationType, null>,
          }}
          {...props}
        />
      }
    >
      <LazyEventHistory
        {...props}
        eventTypeFilters={eventTypeFilters}
        setEventTypeFilters={setEventTypeFilters}
      />
    </SsrSuspense>
  )
}

export const EventHistoryBase = ({
  mode = "default",
  data: dataKey,
  className,
  showFilters,
  excludedFilters,
  useWindow,
  scrollboxClassName,
  activityTableKey,
  showCollectionLinks,
  noPadding,
  hasNext,
  isLoadingNext,
  loadNext,
  panelProps,
  isLoading,
}: Omit<Props, "variables" | "pollingInterval"> & {
  data: EventHistory_data$key
  hasNext: boolean
  isLoadingNext: boolean
  loadNext: LoadMoreFn<OperationType>
  panelProps?: {
    refetch: RefetchFnDynamic<OperationType, null>
    eventTypeFilters: EventType[]
    setEventTypeFilters: React.Dispatch<React.SetStateAction<EventType[]>>
  }
  isLoading?: boolean
}) => {
  const t = useTranslate("components")
  const data = useFragment(
    graphql`
      fragment EventHistory_data on AssetEventType
      @relay(plural: true)
      @argumentDefinitions(showAll: { type: "Boolean", defaultValue: false }) {
        collection {
          ...CollectionCell_collection
        }
        traitCriteria {
          ...CollectionCell_trait
        }
        itemQuantity
        item @include(if: $showAll) {
          __typename
          relayId
          verificationStatus
          ...ItemCell_data
          ...item_url
          ...PortfolioTableItemCellTooltip_item
          ...ActivityTableFullRow_itemData
          ... on AssetType {
            defaultRarityData {
              rank
            }
            collection {
              ...CollectionLink_collection
            }
            assetContract {
              ...CollectionLink_assetContract
            }
          }
          ... on AssetBundleType {
            bundleCollection: collection {
              ...CollectionLink_collection
            }
          }
        }
        relayId
        eventTimestamp
        eventType
        customEventName
        orderStatus
        ...utilsAssetEventLabel
        fromAccount {
          address
          ...AccountLink_data
        }
        perUnitPrice {
          unit
          eth
          usd
        }
        endingPriceType {
          unit
        }
        priceType {
          unit
        }
        payment {
          ...TokenPricePayment
        }
        seller {
          ...AccountLink_data
        }
        sellOrder {
          taker {
            __typename
          }
        }
        toAccount {
          ...AccountLink_data
        }
        winnerAccount {
          ...AccountLink_data
        }
        swap {
          __typename
          makerAssetQuantities(first: 32) {
            edges {
              node {
                asset {
                  __typename
                  relayId
                  verificationStatus
                  ...ItemCell_data
                  ...item_url
                  ...PortfolioTableItemCellTooltip_item
                  ...ActivityTableFullRow_itemData
                  defaultRarityData {
                    rank
                  }
                  collection {
                    ...CollectionLink_collection
                  }
                  assetContract {
                    ...CollectionLink_assetContract
                  }
                }
              }
            }
            count
          }
          takerAssetQuantities(first: 32) {
            count
          }
          ...AssetEventDealCell_swap
          ...DealLink_swap
        }
        ...EventTimestamp_data
      }
    `,
    dataKey,
  )

  if (data.length > 0 && mode !== "full" && panelProps === undefined) {
    throw new Error("panelProps must be defined when mode is not full")
  }

  const scrollboxRef = useRef<HTMLDivElement>(null)
  const eventCopy = useCopy()

  const assetEvents = useMemo(
    () =>
      data
        // TODO: Temp fix for airdrop filtering
        .filter(
          event =>
            getAssetEventLabel({ ref: event, ...eventCopy }) !=
              eventCopy.AIRDROP_LABEL ||
            isSafelisted(event.item?.verificationStatus),
        ),
    [data, eventCopy],
  )

  const eventFilterItems = useEventFilters().filter(
    event => !excludedFilters?.includes(event.filter),
  )

  const renderFilters = () => {
    if (!showFilters) {
      return null
    }

    return (
      <div className="EventHistory--filters">
        {renderFilterDropdown()}
        {renderFilterPills()}
      </div>
    )
  }

  const page: PageProps = {
    loadMore: count => loadNextToLoadMore({ loadNext, count }),
    isLoading: () => isLoadingNext,
    hasMore: () => hasNext,
  }

  const refetchEvents = () => {
    panelProps?.refetch({
      eventTypes: panelProps.eventTypeFilters,
      showAll: true,
    })
  }

  const toggleSelectedEventTypes = (eventType: EventType) => {
    panelProps?.setEventTypeFilters(prev =>
      prev.includes(eventType)
        ? prev.filter(e => e !== eventType)
        : [...prev, eventType],
    )
  }

  useUpdateEffect(() => {
    refetchEvents()
  }, [panelProps?.eventTypeFilters])

  const renderFilterPills = () => {
    if (panelProps?.eventTypeFilters.length === 0) {
      return null
    }

    return (
      <ul className="EventHistory--filter-pills">
        {panelProps?.eventTypeFilters.map(eventType => {
          const label = eventFilterItems.find(
            ({ filter }) => filter === eventType,
          )?.label

          if (!label) {
            return null
          }

          return (
            <li className="EventHistory--filter-pill" key={eventType}>
              <Pill
                text={label}
                onClose={() => toggleSelectedEventTypes(eventType)}
              />
            </li>
          )
        })}

        <VerticalAligned>
          <UnstyledButton onClick={() => panelProps?.setEventTypeFilters([])}>
            <li className="EventHistory--filter-dropdown-clear">
              {t("eventHistory.clearAllCTA", "Clear All")}
            </li>
          </UnstyledButton>
        </VerticalAligned>
      </ul>
    )
  }

  const renderFilterDropdown = () => {
    return (
      <Select
        options={eventFilterItems.map(({ filter, label }) => ({
          value: filter,
          label,
        }))}
        placeholder={t("eventHistory.filter.title", "Filter")}
        readOnly
        onSelect={option => option && toggleSelectedEventTypes(option.value)}
      />
    )
  }

  const renderEventIcon = (eventLabel: EventLabelData) =>
    eventLabel.icon ? (
      <Icon className="EventHistory--icon" value={eventLabel.icon} />
    ) : (
      <CenterAligned className="EventHistory--icon">
        {eventLabel.getIcon({ height: 20, width: 18 })}
      </CenterAligned>
    )

  const renderContent = () => {
    const [event, item, price, unitPrice, quantity, from, to, date] = [
      t("eventHistory.headers.event", "Event"),
      t("eventHistory.headers.item", "Item"),
      t("eventHistory.headers.price", "Price"),
      t("eventHistory.headers.unitPrice", "Unit Price"),
      t("eventHistory.headers.quantity", "Quantity"),
      t("eventHistory.headers.from", "From"),
      t("eventHistory.headers.to", "To"),
      t("eventHistory.headers.date", "Date"),
    ]

    const payoutsHeaders = [
      undefined,
      item,
      unitPrice,
      quantity,
      undefined,
      undefined,
    ]
    const nonFungibleHeaders = [
      event,
      undefined,
      price,
      undefined,
      from,
      to,
      date,
    ]
    const fungibleHeaders = [
      event,
      undefined,
      unitPrice,
      quantity,
      from,
      to,
      date,
    ]
    const fullHeaders = [event, item, unitPrice, quantity, from, to, date]

    return (
      <>
        <Row
          className="EventHistory--header"
          columnIndexClassName={
            mode === "payouts"
              ? {
                  1: "EventHistory--item-col",
                  2: "EventHistory--price-col",
                  3: "EventHistory--quantity-col",
                  4: "EventHistory--earned-col",
                }
              : {
                  0: "EventHistory--event-col",
                  1: "EventHistory--item-col",
                  2: "EventHistory--price-col",
                  3: "EventHistory--quantity-col",
                }
          }
          isHeader
          spaced
        >
          {/* Use `undefined`, `null` or `false` for column to be collapsed and `<></>` for an empty space. */}
          {mode === "payouts"
            ? payoutsHeaders
            : mode === "nonfungible"
            ? nonFungibleHeaders
            : mode === "fungible"
            ? fungibleHeaders
            : fullHeaders}
        </Row>
        {assetEvents.length === 0 ? (
          isLoading ? (
            <div className="EventHistory--no-data">
              <Spinner size="medium" />
            </div>
          ) : (
            <div className="EventHistory--no-data">
              <StyledImage
                alt="No history data image"
                height={100}
                src={NO_HISTORY_DATA_IMG}
                width={582}
              />
              <div className="EventHistory--no-data-text">
                {t("eventHistory.noActivity", "No item activity yet")}
              </div>
            </div>
          )
        ) : (
          assetEvents.map((assetEvent, index) => {
            const customEventName = assetEvent.customEventName
            const fromAccount = assetEvent.seller || assetEvent.fromAccount
            const toAccount = assetEvent.winnerAccount || assetEvent.toAccount
            const eventLabel = getAssetEventLabel({
              ref: assetEvent,
              ...eventCopy,
            })
            const item = assetEvent.item
            const tokenPricePayment = assetEvent.payment
              ? getTokenPricePayment(assetEvent.payment)
              : null

            const quantity =
              mode === "full" || mode === "fungible" || mode === "payouts"
                ? assetEvent.itemQuantity
                : undefined

            const setLastClickedBestAskData = () => {
              const itemId = item?.relayId
              const bestAskEthPrice = assetEvent.perUnitPrice?.eth

              if (itemId && bestAskEthPrice) {
                localforage.setItem(
                  LAST_CLICKED_BEST_ASK_DATA_KEY,
                  `${itemId}-${bestAskEthPrice}`,
                )
              }
            }

            return (
              <Row
                bordered={index !== assetEvents.length - 1}
                className={selectClassNames("EventHistory", {
                  row: true,
                })}
                columnIndexClassName={
                  mode === "payouts"
                    ? {
                        1: "EventHistory--item-col",
                        2: "EventHistory--price-col",
                        3: "EventHistory--quantity-col",
                        4: "EventHistory--earned-col",
                      }
                    : {
                        0: "EventHistory--event-col",
                        1: "EventHistory--item-col",
                        2: "EventHistory--price-col",
                        3: "EventHistory--quantity-col",
                      }
                }
                key={assetEvent.relayId}
                spaced
              >
                {mode === "payouts" ? undefined : (
                  <>
                    {renderEventIcon(eventLabel)}
                    <span>
                      {customEventName || eventLabel.name}
                      {assetEvent.sellOrder?.taker?.__typename && (
                        <>
                          {" "}
                          {t(
                            "eventHistory.activityEventLabel.privateListing",
                            "- Reserved",
                          )}
                        </>
                      )}
                    </span>
                    {assetEvent.orderStatus ? (
                      <AssetEventOrderStatus
                        orderStatus={assetEvent.orderStatus}
                      />
                    ) : null}
                  </>
                )}
                {mode === "full" || mode === "payouts" ? (
                  item ? (
                    <ItemCell item={item} onClick={setLastClickedBestAskData} />
                  ) : (
                    <CollectionCell
                      collection={assetEvent.collection}
                      trait={assetEvent.traitCriteria}
                    />
                  )
                ) : undefined}
                {assetEvent.perUnitPrice && tokenPricePayment ? (
                  <div className="EventHistory--prices">
                    <TokenPrice
                      className="EventHistory--price"
                      maxDecimalPlaces={MAX_DISPLAYED_DECIMAL_PLACES}
                      price={bn(assetEvent.perUnitPrice.unit)}
                      {...tokenPricePayment}
                    />
                    {assetEvent.endingPriceType &&
                    assetEvent.priceType &&
                    assetEvent.endingPriceType.unit !==
                      assetEvent.priceType.unit ? (
                      <>
                        <Tooltip
                          content={
                            <Flex className="items-center">
                              {t(
                                "eventHistory.priceChange",
                                "Price {{action}} from {{startPrice}} to {{endPrice}}",
                                {
                                  action:
                                    assetEvent.endingPriceType.unit <
                                    assetEvent.perUnitPrice.unit
                                      ? t("eventHistory.declines", "declines")
                                      : t(
                                          "eventHistory.increases",
                                          "increases",
                                        ),
                                  startPrice: (
                                    <TokenPrice
                                      color="white"
                                      maxDecimalPlaces={
                                        MAX_DISPLAYED_DECIMAL_PLACES
                                      }
                                      price={bn(assetEvent.perUnitPrice.unit)}
                                      {...tokenPricePayment}
                                      isInline
                                    />
                                  ),
                                  endPrice: (
                                    <TokenPrice
                                      color="white"
                                      maxDecimalPlaces={
                                        MAX_DISPLAYED_DECIMAL_PLACES
                                      }
                                      price={bn(
                                        assetEvent.endingPriceType.unit,
                                      )}
                                      {...tokenPricePayment}
                                      isInline
                                    />
                                  ),
                                },
                              )}
                            </Flex>
                          }
                        >
                          <Icon value="keyboard_arrow_right" />
                        </Tooltip>
                        <TokenPrice
                          className="EventHistory--price"
                          maxDecimalPlaces={MAX_DISPLAYED_DECIMAL_PLACES}
                          price={bn(assetEvent.endingPriceType.unit)}
                          {...tokenPricePayment}
                        />
                      </>
                    ) : null}
                  </div>
                ) : (
                  <></>
                )}
                {mode === "nonfungible" ? undefined : quantity ? (
                  quantityDisplay(quantity)
                ) : (
                  <></>
                )}
                {mode === "payouts" ? undefined : fromAccount ? (
                  <AccountLink
                    dataKey={fromAccount}
                    handleOverflow
                    variant="no-image"
                  />
                ) : (
                  <></>
                )}
                {mode === "payouts" ? undefined : toAccount ? (
                  <AccountLink
                    dataKey={toAccount}
                    handleOverflow
                    variant="no-image"
                  />
                ) : (
                  <></>
                )}

                <EventTimestamp data={assetEvent} />
              </Row>
            )
          })
        )}
        <ScrollingPaginator
          intersectionOptions={{ root: scrollboxRef.current }}
          isFirstPageLoading={!data}
          page={page}
          size={PAGE_SIZE}
        />
      </>
    )
  }

  if (mode === "full") {
    return (
      <>
        <Block padding={{ xl: noPadding ? "0" : "0 28px" }}>
          <ActivityTable
            assetEvents={assetEvents}
            key={activityTableKey}
            pagination={{ page, size: PAGE_SIZE }}
            showCollectionLinks={showCollectionLinks}
          />
        </Block>
      </>
    )
  }

  return (
    <DivContainer className={className}>
      <Panel
        bodyClassName="EventHistory--Panel"
        icon="swap_vert"
        id="event-history"
        isContentPadded={false}
        mode={mode === "payouts" ? "always-open" : "start-open"}
        title={
          mode === "payouts"
            ? t(
                "eventHistory.creatorEarningsHistory",
                "Creator Earnings History",
              )
            : t("eventHistory.itemActivity", "Item Activity")
        }
      >
        {renderFilters()}

        {useWindow ? (
          renderContent()
        ) : (
          <Scrollbox
            className={clsx("EventHistory--container", scrollboxClassName)}
            ref={scrollboxRef}
          >
            {renderContent()}
          </Scrollbox>
        )}
      </Panel>
    </DivContainer>
  )
}

const query = graphql`
  query EventHistoryQuery(
    $archetype: ArchetypeInputType
    $bundle: BundleSlug
    $collections: [CollectionSlug!]
    $categories: [CollectionSlug!]
    $chains: [ChainScalar!]
    $eventTypes: [EventType!]
    $cursor: String
    $count: Int = 16
    $showAll: Boolean = false
    $identity: IdentityInputType
    $stringTraits: [TraitInputType!]
    $eventTimestamp_Gt: DateTime
    $rarityFilter: RarityFilterType
  ) {
    ...EventHistoryGeneral_data
      @arguments(
        cursor: $cursor
        count: $count
        archetype: $archetype
        bundle: $bundle
        showAll: $showAll
        eventTypes: $eventTypes
        collections: $collections
        categories: $categories
        chains: $chains
        identity: $identity
        stringTraits: $stringTraits
        eventTimestamp_Gt: $eventTimestamp_Gt
        rarityFilter: $rarityFilter
      )
  }
`

const LazyEventHistory = ({
  eventTypeFilters,
  setEventTypeFilters,
  pollingInterval,
  ...restProps
}: Props & {
  eventTypeFilters: EventType[]
  setEventTypeFilters: React.Dispatch<React.SetStateAction<EventType[]>>
}) => {
  const dataKey = useLazyLoadQuery<EventHistoryQuery>(
    query,
    restProps.variables,
  )

  const { data, hasNext, isLoadingNext, loadNext, refetch } =
    usePaginationFragment<
      EventHistoryPaginationQuery,
      EventHistoryGeneral_data$key
    >(
      graphql`
        fragment EventHistoryGeneral_data on Query
        @argumentDefinitions(
          archetype: { type: "ArchetypeInputType" }
          bundle: { type: "BundleSlug" }
          categories: { type: "[CollectionSlug!]" }
          collections: { type: "[CollectionSlug!]" }
          chains: { type: "[ChainScalar!]" }
          eventTypes: { type: "[EventType!]" }
          cursor: { type: "String" }
          count: { type: "Int", defaultValue: 16 }
          identity: { type: "IdentityInputType" }
          showAll: { type: "Boolean", defaultValue: false }
          stringTraits: { type: "[TraitInputType!]" }
          eventTimestamp_Gt: { type: "DateTime" }
          rarityFilter: { type: "RarityFilterType" }
        )
        @refetchable(queryName: "EventHistoryPaginationQuery") {
          eventActivity(
            after: $cursor
            bundle: $bundle
            archetype: $archetype
            first: $count
            categories: $categories
            collections: $collections
            chains: $chains
            eventTypes: $eventTypes
            identity: $identity
            includeHidden: true
            stringTraits: $stringTraits
            eventTimestamp_Gt: $eventTimestamp_Gt
            rarityFilter: $rarityFilter
          ) @connection(key: "EventHistory_eventActivity") {
            edges {
              node {
                ...EventHistory_data @arguments(showAll: $showAll)
                eventTimestamp
              }
            }
          }
        }
      `,
      dataKey,
    )

  const initialEvents = getNodes(data.eventActivity)

  const [polledEvents, setPolledEvents] = useState<
    Node<EventHistoryGeneral_data$data["eventActivity"]>[]
  >([])

  // Events are sorted by eventTimestamp in descending order
  const latestEventTimestamp =
    first(polledEvents)?.eventTimestamp ?? first(initialEvents)?.eventTimestamp

  usePollingQuery<EventHistoryPollQuery>(
    pollingQuery,
    {
      ...restProps.variables,
      count: 32,
      eventTypes: eventTypeFilters,
      eventTimestamp_Gt:
        latestEventTimestamp ?? restProps.variables.eventTimestamp_Gt,
    },
    {
      delay: pollingInterval,
      skip: !pollingInterval,
      skipOnHidden: true,
      onPoll: newData => {
        const newNodes = getNodes(newData.eventActivity)
        setPolledEvents(prev => [...newNodes, ...prev])
      },
    },
  )

  return (
    <EventHistoryBase
      data={[...polledEvents, ...initialEvents]}
      hasNext={hasNext}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      panelProps={{
        refetch,
        eventTypeFilters,
        setEventTypeFilters,
      }}
      {...restProps}
    />
  )
}

const StyledImage = styled(Image)`
  ${themeVariant({
    variants: {
      dark: {
        opacity: 0.5,
      },
    },
  })}
`

const DivContainer = styled.div`
  overflow-x: scroll;

  .EventHistory--Panel {
    overflow-x: scroll;
  }

  .EventHistory--container {
    max-height: 332px;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: auto;
  }

  .EventHistory--no-data {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 299px;
    justify-content: center;
    width: 100%;

    .EventHistory--no-data-text {
      display: flex;
      font-size: 16px;
      margin-top: 4px;
      justify-content: center;
    }
  }

  .EventHistory--filters {
    border-bottom: 1px solid
      ${props => props.theme.colors.components.border.level2};
    padding: 16px;

    .EventHistory--filter-pills {
      display: flex;
      flex-wrap: wrap;
      margin: 16px 0 0 0;

      .EventHistory--filter-pill,
      .EventHistory--filter-dropdown-clear {
        margin: 5px;
      }

      .EventHistory--filter-dropdown-clear {
        color: ${props => props.theme.colors.primary};
        cursor: pointer;
        opacity: 0.9;

        @media (hover: hover) {
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .EventHistory--filter-option {
    display: flex;

    .EventHistory--filter-option-label {
      margin-left: 8px;
    }
  }

  .EventHistory--icon {
    font-size: 18px;
    vertical-align: middle;
    margin-right: 8px;
  }

  .EventHistory--prices {
    display: flex;
  }

  .EventHistory--price {
    font-weight: 400;
  }

  .EventHistory--price {
    font-weight: 400;
  }

  .EventHistory--item-link {
    color: black;
  }

  .EventHistory--event-col {
    flex-basis: 130px;
  }

  .EventHistory--item-col {
    flex-basis: 80px;
  }

  .EventHistory--price-col {
    flex-basis: 140px;
  }

  .EventHistory--quantity-col {
    flex-basis: 80px;
  }

  .EventHistory--earned-col {
    flex-basis: 80px;
  }

  .EventHistory--dimmed {
    .EventHistory--item-col,
    .EventHistory--price-col,
    .EventHistory--quantity-col,
    .EventHistory--no-payout-earned {
      opacity: 0.5;
    }
  }

  @keyframes dropDown {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0%);
    }
  }

  ${media({
    sm: css`
      overflow: auto;

      .EventHistory--Panel {
        overflow-x: auto;
      }

      .EventHistory--item-col {
        flex-basis: 300px;
      }

      .EventHistory--price-col {
        flex-basis: 100px;
      }

      .EventHistory--quantity-col {
        flex-basis: 100px;
      }

      .EventHistory--earned-col {
        flex-basis: 100px;
      }
    `,
    xxl: css`
      .EventHistory--price-col {
        flex-basis: 10px;
      }

      .EventHistory--quantity-col {
        flex-basis: 10px;
      }

      .EventHistory--earned-col {
        flex-basis: 10px;
      }

      .EventHistory--event-col {
        flex-basis: 30px;
      }
    `,
  })}

  .EventHistory--header {
    // Special casing so the header doens't bleed through when sticky
    background-color: ${props =>
      props.theme.type === "light" ? props.theme.colors.base2 : "#1B1B1B"};
  }
`
