import React from "react"
import { FlexEnd, SpaceBetween, Text } from "@opensea/ui-kit"
import { ItemCell } from "@/components/assets/ItemCell"
import {
  getTokenPricePayment,
  TokenPrice,
} from "@/components/assets/price/TokenPrice.react"
import { CollectionCell } from "@/components/collections/CollectionCell.react"
import { Overflow } from "@/components/common/Overflow"
import { EventTimestamp } from "@/components/events/EventTimestamp"
import { Block } from "@/design-system/Block"
import {
  FeatureTable,
  FeatureTableRenderPrimaryProps,
} from "@/design-system/FeatureTable"
import { Item } from "@/design-system/Item"
import { AssetEventDealCell } from "@/features/activity/components/AssetEventDealCell"
import { AssetEventDealStatus } from "@/features/activity/components/AssetEventDealStatus.react"
import { DealLink } from "@/features/deals/components/DealLink"
import { isDealEventType } from "@/features/deals/constants"
import { useTranslate } from "@/hooks/useTranslate"
import { bn, MAX_DISPLAYED_DECIMAL_PLACES } from "@/lib/helpers/numberUtils"
import { AssetEventOrderStatus } from "../components/AssetEventOrderStatus.react"
import { useCopy } from "../constants"
import { ActivityTableItem } from "./ActivityTable"
import { ActivityTableRowPrimarySkeleton } from "./ActivityTableRowPrimarySkeleton"
import {
  ActivityTableCollectionLink,
  CoverLink,
  CoverLinkContainer,
} from "./styles"
import { getAssetEventItem, getAssetEventLabel } from "./utils"

type ActivityTableRowPrimaryProps =
  FeatureTableRenderPrimaryProps<ActivityTableItem>

export const ActivityTableRowPrimary = ({
  data,
  ShowMoreButton,
}: ActivityTableRowPrimaryProps) => {
  const t = useTranslate("collection")
  const eventCopy = useCopy()
  const { assetEvent, showCollectionLinks, href } = data
  if (!assetEvent) {
    return <ActivityTableRowPrimarySkeleton />
  }

  const eventLabel = getAssetEventLabel({ ref: assetEvent, ...eventCopy })

  const { orderStatus, perUnitPrice, payment, sellOrder, swap } = assetEvent
  const isDeal = isDealEventType(assetEvent.eventType)
  const item = getAssetEventItem(assetEvent)
  const tokenPricePayment = payment ? getTokenPricePayment(payment) : null

  const showCollectionLink = showCollectionLinks && item?.collection
  return (
    <CoverLinkContainer>
      <SpaceBetween className="h-full w-full">
        <Item.Content style={{ alignItems: "stretch" }}>
          {isDeal && assetEvent.swap ? (
            <AssetEventDealCell swap={assetEvent.swap} />
          ) : item ? (
            <ItemCell
              fullscreenBreakpoint="xs"
              item={item}
              link={false}
              renderName={({ name, quantityDisplayText, className }) => (
                <Block
                  className={className}
                  marginLeft="8px"
                  maxWidth="calc(100% - 56px)"
                >
                  <Item.Title
                    className="text-left"
                    data-testid="activity-table-item-name"
                  >
                    <CoverLink href={href ?? undefined}>
                      <Overflow lines={2}>
                        {name} {quantityDisplayText}
                      </Overflow>
                    </CoverLink>
                  </Item.Title>
                  {showCollectionLink && (
                    <Item.Description>
                      <Overflow lines={1}>
                        <Text className="flex text-secondary" size="tiny">
                          <ActivityTableCollectionLink
                            assetContract={item.assetContract}
                            collection={item.collection}
                            isSmall
                          />
                        </Text>
                      </Overflow>
                    </Item.Description>
                  )}
                  <ShowMoreButton />
                </Block>
              )}
            />
          ) : (
            <CollectionCell
              collection={assetEvent.collection}
              fullscreenBreakpoint="xs"
              link={false}
              trait={assetEvent.traitCriteria}
            />
          )}
        </Item.Content>
        <Item.Side>
          <Item.Description>
            <span>
              {eventLabel.name}
              {sellOrder?.taker?.__typename && (
                <> {t("activityEventLabel.privateListing", "- Reserved")}</>
              )}
            </span>
            {orderStatus ? (
              <AssetEventOrderStatus orderStatus={orderStatus} />
            ) : null}
            {isDeal && (
              <AssetEventDealStatus
                eventType={assetEvent.eventType}
                size="small"
              />
            )}
          </Item.Description>
          <FlexEnd>
            {perUnitPrice && tokenPricePayment ? (
              <TokenPrice
                maxDecimalPlaces={MAX_DISPLAYED_DECIMAL_PLACES}
                price={bn(perUnitPrice.unit)}
                {...tokenPricePayment}
              />
            ) : isDeal && swap ? (
              <DealLink swap={swap} target="_blank">
                {t(
                  "activityEventLabel.dealTakerItemCount",
                  {
                    "0": "{{count}} items",
                    one: "{{count}} item",
                    other: "{{count}} items",
                  },
                  {
                    count: swap.takerAssetQuantities.count,
                  },
                )}
              </DealLink>
            ) : (
              <FeatureTable.StatText>---</FeatureTable.StatText>
            )}
          </FlexEnd>
          <Item.Description>
            <EventTimestamp data={assetEvent} subtle />
          </Item.Description>
        </Item.Side>
      </SpaceBetween>
    </CoverLinkContainer>
  )
}
