import React from "react"
import { MaterialIcon } from "@opensea/ui-kit"
import { AirdropIcon } from "@/components/svgs/AirdropIcon.react"
import { CollectionOfferIcon } from "@/components/svgs/CollectionOfferIcon.react"
import { OfferIcon } from "@/components/svgs/OfferIcon.react"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetEventEventType } from "@/lib/graphql/__generated__/EventHistory_data.graphql"

export type EventLabelData = EventLabelWithIconName | EventLabelWithIconElement

type CustomIconProps = {
  height?: number
  width?: number
}

type EventLabelBaseData = {
  name: string
}

type EventLabelWithIconName = EventLabelBaseData & {
  icon: MaterialIcon
  getIcon?: undefined
}

type EventLabelWithIconElement = EventLabelBaseData & {
  getIcon: (props?: CustomIconProps) => React.ReactNode
  icon?: undefined
}

const getOfferIcon = (props: CustomIconProps | undefined) => (
  <OfferIcon {...props} />
)

export const useCopy = () => {
  const t = useTranslate("activity")

  // All deal events have the same icon and name. The status is indicated via
  // the `AssetEventDealStatus` component.
  const dealEventLabelData = {
    icon: "handshake",
    name: t("activity.events.swap.label", "Deal"),
  } as const

  const EVENT_TYPES: Record<AssetEventEventType, EventLabelData> = {
    BID_WITHDRAWN: {
      icon: "sentiment_satisfied",
      name: t("activity.events.cancelOffer.label", "Offer Cancel"),
    },
    CREATED: { icon: "sell", name: t("activity.events.list.label", "List") },
    CANCELLED: {
      icon: "cancel",
      name: t("activity.events.cancel.label", "Cancel"),
    },
    BULK_CANCEL: {
      icon: "cancel",
      name: t("activity.events.bulkCancel.label", "Bulk Cancel"),
    },
    SUCCESSFUL: {
      icon: "shopping_cart",
      name: t("activity.events.sale.label", "Sale"),
    },
    TRANSFER: {
      icon: "multiple_stop",
      name: t("activity.events.transfer.label", "Transfer"),
    },
    APPROVE: {
      icon: "check_box",
      name: t("activity.events.approve.label", "Approve"),
    },
    CUSTOM: { icon: "star", name: t("activity.events.custom.label", "Custom") },
    PAYOUT: {
      icon: "attach_money",
      name: t("activity.events.payout.label", "Payout"),
    },
    BID_ENTERED: { name: "Offer", getIcon: getOfferIcon },
    OFFER_ENTERED: { name: "Offer", getIcon: getOfferIcon },
    COLLECTION_OFFER: {
      name: t("activity.events.collectionOffer.label", "Collection Offer"),
      getIcon: (props: CustomIconProps | undefined) => (
        <CollectionOfferIcon {...props} />
      ),
    },
    TRAIT_OFFER: {
      name: t("activity.events.collectionOffer.label", "Collection Offer"),
      getIcon: (props: CustomIconProps | undefined) => (
        <CollectionOfferIcon {...props} />
      ),
    },
    SWAP_CANCELLED: dealEventLabelData,
    SWAP_CREATED: dealEventLabelData,
    SWAP_ACCEPTED: dealEventLabelData,
    REDEMPTION: {
      icon: "redeem",
      name: t("activity.events.redemption.label", "Redemption"),
    },
    "%future added value": { icon: "forum", name: "Unknown" },
  }

  const AIRDROP_LABEL: EventLabelData = {
    getIcon: (props: CustomIconProps | undefined) => <AirdropIcon {...props} />,
    name: t("activity.events.airdrop.label", "Airdrop"),
  }

  const MINT_LABEL: EventLabelData = {
    icon: "auto_awesome",
    name: t("activity.events.mint.label", "Mint"),
  }

  return {
    EVENT_TYPES,
    AIRDROP_LABEL,
    MINT_LABEL,
  }
}
