import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { AssetMedia } from "@/components/assets/AssetMedia"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import { Overflow } from "@/components/common/Overflow"
import { Item } from "@/design-system/Item"
import { DealLink } from "@/features/deals/components/DealLink"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetEventDealCell_swap$key } from "@/lib/graphql/__generated__/AssetEventDealCell_swap.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { bn, display } from "@/lib/helpers/numberUtils"

type Props = {
  swap: AssetEventDealCell_swap$key
}

export const AssetEventDealCell = ({ swap: swapKey }: Props) => {
  const t = useTranslate("components")
  const swap = useFragment(
    graphql`
      fragment AssetEventDealCell_swap on SwapType {
        makerAssetQuantities(first: 32) {
          edges {
            node {
              asset {
                relayId
                collection {
                  name
                  isVerified
                }
                decimals
                displayName
                symbol
                tokenStandard
                ...AssetMedia_asset
              }
              quantity
            }
          }
        }
        ...DealLink_swap
      }
    `,
    swapKey,
  )

  const assetQuantities = getNodes(swap.makerAssetQuantities)

  const paymentTokenAssets = assetQuantities.filter(
    aq => aq.asset.tokenStandard === "ERC20",
  )

  const nftAssets = assetQuantities.filter(
    aq => aq.asset.tokenStandard !== "ERC20",
  )
  const firstNftAsset = nftAssets[0]
  const isAllVerified = nftAssets.every(aq => aq.asset.collection.isVerified)
  const collectionNames = Array.from(
    new Set(nftAssets.map(aq => aq.asset.collection.name)),
  ).join(", ")

  return (
    <DealLink swap={swap} target="_blank">
      <Item variant="unstyled">
        <Item.Avatar className="relative rounded-xl" size={48}>
          <AssetMedia asset={firstNftAsset.asset} size={48} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title data-testid="deal-cell-title">
            <Overflow lines={1}>
              {nftAssets.length === 1 && paymentTokenAssets.length === 0
                ? firstNftAsset.asset.displayName
                : t(
                    "assetEventCell.deal.title",
                    {
                      "0": "{{count}} items",
                      one: "{{count}} item",
                      other: "{{count}} items",
                    },
                    {
                      count: nftAssets.length,
                    },
                  )}
              {paymentTokenAssets.map(token => (
                <React.Fragment key={token.asset.relayId}>
                  {" "}
                  + {display(bn(token.quantity, token.asset.decimals))}
                  &nbsp;{token.asset.symbol}
                </React.Fragment>
              ))}
            </Overflow>
          </Item.Title>
          <Item.Description className="flex text-secondary" size="tiny">
            <Overflow lines={1}>{collectionNames}</Overflow>
            {isAllVerified && (
              <VerificationIcon
                showTooltip={false}
                size="tiny"
                verificationStatus="VERIFIED"
              />
            )}
          </Item.Description>
        </Item.Content>
      </Item>
    </DealLink>
  )
}
