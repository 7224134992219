import React from "react"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { FiatPrice } from "@/components/assets/price/FiatPrice.react"
import { Overflow } from "@/components/common/Overflow"
import { FeatureTable } from "@/design-system/FeatureTable"
import { useTranslate } from "@/hooks/useTranslate"
import { bn } from "@/lib/helpers/numberUtils"
import { EMPTY_PRICE_DISPLAY } from "../../../constants"
import { ActivityTableItem } from "./ActivityTable"

type ActivityTableMoreProps = {
  data: ActivityTableItem
}

export const ActivityTableMore = ({ data }: ActivityTableMoreProps) => {
  const t = useTranslate("activity")
  if (!data.assetEvent) {
    return null
  }

  const assetEvent = data.assetEvent
  const { seller, winnerAccount, perUnitPrice, itemQuantity, item } = assetEvent
  const fromAccount = seller || assetEvent.fromAccount
  const toAccount = winnerAccount || assetEvent.toAccount
  const rarityRank = item?.defaultRarityData?.rank

  return (
    <>
      <FeatureTable.StatContainer>
        <FeatureTable.StatHeader>
          {t("moreDetails.priceInUSD", "USD Price")}
        </FeatureTable.StatHeader>
        <FeatureTable.StatText>
          {perUnitPrice ? (
            <FiatPrice fontWeight={500} price={bn(perUnitPrice.usd)} />
          ) : (
            EMPTY_PRICE_DISPLAY
          )}
        </FeatureTable.StatText>
      </FeatureTable.StatContainer>
      <FeatureTable.StatContainer>
        <FeatureTable.StatHeader>
          {t("moreDetails.rarity", "Rarity")}
        </FeatureTable.StatHeader>
        <FeatureTable.StatText className="w-full text-center" weight="semibold">
          <Overflow>
            {rarityRank ? `#${rarityRank}` : EMPTY_PRICE_DISPLAY}
          </Overflow>
        </FeatureTable.StatText>
      </FeatureTable.StatContainer>
      <FeatureTable.StatContainer>
        <FeatureTable.StatHeader>
          {t("moreDetails.quantity", "Quantity")}
        </FeatureTable.StatHeader>
        <FeatureTable.StatText className="w-full text-center" weight="semibold">
          <Overflow>{itemQuantity || EMPTY_PRICE_DISPLAY}</Overflow>
        </FeatureTable.StatText>
      </FeatureTable.StatContainer>
      <FeatureTable.StatContainer>
        <FeatureTable.StatHeader>
          {t("moreDetails.fromAccount", "From")}
        </FeatureTable.StatHeader>
        {fromAccount ? (
          <AccountLink
            dataKey={fromAccount}
            fontWeight={500}
            handleOverflow
            justifyContent="center"
            variant="no-image"
          />
        ) : (
          <FeatureTable.StatText weight="semibold">---</FeatureTable.StatText>
        )}
      </FeatureTable.StatContainer>
      <FeatureTable.StatContainer>
        <FeatureTable.StatHeader>
          {t("moreDetails.toAccount", "To")}
        </FeatureTable.StatHeader>
        {toAccount ? (
          <AccountLink
            dataKey={toAccount}
            fontWeight={500}
            handleOverflow
            justifyContent="center"
            variant="no-image"
          />
        ) : (
          <FeatureTable.StatText weight="semibold">---</FeatureTable.StatText>
        )}
      </FeatureTable.StatContainer>
    </>
  )
}
