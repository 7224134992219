import React from "react"
import {
  Breakpoint,
  Media,
  Text,
  classNames,
  FlexColumn,
  Flex,
  FlexProps,
} from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { Overflow } from "@/components/common/Overflow"
import { VerifiedIcon } from "@/components/common/VerifiedIcon.react"
import { Image } from "@/design-system/Image"
import { CollectionCell_collection$key } from "@/lib/graphql/__generated__/CollectionCell_collection.graphql"
import { CollectionCell_trait$key } from "@/lib/graphql/__generated__/CollectionCell_trait.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { stringifyQueryParams } from "@/lib/helpers/urls"
import { PLACEHOLDER_IMAGE } from "../../constants"

type CollectionCellVariant = "small" | "default"

type CollectionCellRenderNameProps = {
  name: string
  isVerified: boolean
  trait?: string
  className?: string
  variant?: CollectionCellVariant
}

const CollectionCellName = ({
  name,
  isVerified,
  className,
  trait,
  variant = "default",
}: CollectionCellRenderNameProps) => {
  const renderTrait = () => (
    <Overflow as="span" lines={1}>
      {trait}
    </Overflow>
  )
  return (
    <FlexColumn
      className={classNames(
        "ml-2 hidden w-full items-start sm:ml-3 sm:flex",
        variant === "small" ? "ml-3" : "ml-2",
        className,
      )}
    >
      {variant === "small" ? (
        <Flex className="w-full">
          <Text.Body className="truncate" size="small" weight="semibold">
            {name}
          </Text.Body>
          {isVerified && <SmallVariantVerifiedIcon size="small" />}
        </Flex>
      ) : (
        <Overflow as="span" lines={trait ? 1 : 2}>
          {name} {isVerified && <InlineVerifiedIcon size="small" />}
        </Overflow>
      )}
      <Flex>
        {trait &&
          (variant === "small" ? (
            <Text.Body className="truncate text-secondary" size="tiny">
              {renderTrait()}
            </Text.Body>
          ) : (
            <Text asChild color="secondary" size="tiny" weight="semibold">
              <p>{renderTrait()}</p>
            </Text>
          ))}
      </Flex>
    </FlexColumn>
  )
}

type CollectionCellProps = {
  collection: CollectionCell_collection$key | null
  trait?: CollectionCell_trait$key | null
  fullscreenBreakpoint?: Breakpoint
  renderName?: ({
    name,
    isVerified,
  }: CollectionCellRenderNameProps) => React.ReactNode
  link?: boolean
  onClick?: () => unknown // TODO: Is this prop needed? Doesn't seem to be used anywhere
  variant?: CollectionCellVariant
  className?: string
}

export const CollectionCell = ({
  collection: collectionKey,
  trait: traitKey,
  fullscreenBreakpoint = "sm",
  renderName = CollectionCellName,
  link = true,
  onClick,
  variant = "default",
  className,
}: CollectionCellProps) => {
  const collection = useFragment(
    graphql`
      fragment CollectionCell_collection on CollectionType {
        name
        imageUrl
        isVerified
        ...collection_url
      }
    `,
    collectionKey,
  )
  const trait = useFragment(
    graphql`
      fragment CollectionCell_trait on TraitType {
        traitType
        value
      }
    `,
    traitKey ?? null,
  )
  if (!collection) {
    return null
  }

  const size = variant === "small" ? "36" : "48"
  const name = collection.name
  const href = `${getCollectionUrl(collection)}${
    trait
      ? stringifyQueryParams({
          search: {
            stringTraits: [{ name: trait.traitType, values: [trait.value] }],
            sortAscending: true,
            sortBy: "UNIT_PRICE",
          },
        })
      : ""
  }`

  const content = (
    <>
      <ImageContainer $size={size}>
        <Image
          alt=""
          height={size}
          layout="fill"
          objectFit="cover"
          src={collection.imageUrl || PLACEHOLDER_IMAGE}
          width={size}
        />
      </ImageContainer>
      <Media greaterThanOrEqual={fullscreenBreakpoint}>
        {mediaClassName =>
          renderName({
            name,
            isVerified: collection.isVerified,
            className: mediaClassName,
            trait: trait ? `${trait.traitType}: ${trait.value}` : undefined,
            variant,
          })
        }
      </Media>
    </>
  )

  if (link) {
    return (
      <Container asChild className={className} onClick={onClick}>
        <Link href={href}>{content}</Link>
      </Container>
    )
  }

  return (
    <Container className={className} onClick={onClick}>
      {content}
    </Container>
  )
}

const Container = ({ className, ...props }: FlexProps) => (
  <Flex
    {...props}
    className={classNames(
      "items-center text-inherit hover:text-inherit",
      className,
    )}
  />
)

const ImageContainer = styled.div<{ $size: string }>`
  border: 1px solid ${props => props.theme.colors.components.border.level2};
  border-radius: ${props => props.theme.borderRadius.default};
  flex: none;
  height: ${props => props.$size}px;
  width: ${props => props.$size}px;
  overflow: hidden;
  position: relative;
`

const InlineVerifiedIcon = styled(VerifiedIcon)`
  display: inline;
  vertical-align: middle;
`

const SmallVariantVerifiedIcon = styled(VerifiedIcon)`
  margin-left: 4px;
  flex-shrink: 0;
`
