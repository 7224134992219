/**
 * @generated SignedSource<<a4de53002ed2e5a3b4895eb17e5d3605>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionCell_trait$data = {
  readonly traitType: string;
  readonly value: string | null;
  readonly " $fragmentType": "CollectionCell_trait";
};
export type CollectionCell_trait$key = {
  readonly " $data"?: CollectionCell_trait$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionCell_trait">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionCell_trait",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "traitType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "TraitType",
  "abstractKey": null
};

(node as any).hash = "c74d1e493e4aeb33e05ebeaf52691db9";

export default node;
