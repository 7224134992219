import React from "react"
import { Flex, SpaceBetween, Skeleton } from "@opensea/ui-kit"
import { ItemSkeleton } from "@/design-system/ItemSkeleton"

export const ActivityTableRowPrimarySkeleton = React.memo(
  function ActivityTableRowPrimarySkeleton() {
    return (
      <SpaceBetween className="h-32 w-full border-b border-b-level-2">
        <Flex className="mr-2 items-center rounded-default">
          <Skeleton.Block className="h-12 w-12" />
        </Flex>

        <ItemSkeleton.Content>
          <ItemSkeleton.Title style={{ marginBottom: "6px" }} />
          <ItemSkeleton.Description />
        </ItemSkeleton.Content>

        <ItemSkeleton.Side>
          <ItemSkeleton.Description style={{ marginBottom: "6px" }} />
          <ItemSkeleton.Title />
          <ItemSkeleton.Description style={{ width: "70%" }} />
        </ItemSkeleton.Side>
      </SpaceBetween>
    )
  },
)
