import React from "react"
import { graphql, useFragment } from "react-relay"
import { Link, LinkProps } from "@/components/common/Link"
import { getDealUrl } from "@/features/deals/urls"
import { DealLink_swap$key } from "@/lib/graphql/__generated__/DealLink_swap.graphql"

type Props = {
  swap: DealLink_swap$key
  children?: React.ReactNode
} & Omit<LinkProps, "href">

export const DealLink = ({ swap: swapKey, children, ...props }: Props) => {
  const { relayId } = useFragment<DealLink_swap$key>(
    graphql`
      fragment DealLink_swap on SwapType {
        relayId
      }
    `,
    swapKey,
  )

  return (
    <Link href={getDealUrl(relayId)} {...props}>
      {children}
    </Link>
  )
}
