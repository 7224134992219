import React from "react"
import { Flex, Skeleton } from "@opensea/ui-kit"
import { FeatureTable } from "@/design-system/FeatureTable"
import { IActivityTableCellWidths } from "./ActivityTable"

type Props = {
  cellWidths: IActivityTableCellWidths
}

export const ActivityTableFullRowSkeleton = React.memo(
  function ActivityTableFullRowSkeleton({ cellWidths }: Props) {
    return (
      <>
        <FeatureTable.Cell height="48px" width={cellWidths.eventLabelCellWidth}>
          <Skeleton className="h-auto justify-center">
            <Skeleton.Row>
              <Skeleton.Line className="h-[22px] w-full" />
            </Skeleton.Row>
          </Skeleton>
        </FeatureTable.Cell>
        <FeatureTable.Cell width={cellWidths.itemCellWidth}>
          <Skeleton className="h-auto justify-center">
            <Skeleton.Row className="items-center">
              <Flex className="mr-2 rounded-default">
                <Skeleton.Block className="h-12 w-12" />
              </Flex>
              <Skeleton.Line className="h-[22px] w-full" />
            </Skeleton.Row>
          </Skeleton>
        </FeatureTable.Cell>
        <FeatureTable.Cell width={cellWidths.priceCellWidth}>
          <Skeleton className="h-auto justify-center">
            <Skeleton.Row>
              <Skeleton.Line className="h-[22px] w-full" />
            </Skeleton.Row>
          </Skeleton>
        </FeatureTable.Cell>
        <FeatureTable.Cell width={cellWidths.quantityCellWidth}>
          <Skeleton className="h-auto justify-center">
            <Skeleton.Row>
              <Skeleton.Line className="h-[22px] w-full" />
            </Skeleton.Row>
          </Skeleton>
        </FeatureTable.Cell>
        <FeatureTable.Cell width={cellWidths.fromCellWidth}>
          <Skeleton className="h-auto justify-center">
            <Skeleton.Row>
              <Skeleton.Line className="h-[22px] w-full" />
            </Skeleton.Row>
          </Skeleton>
        </FeatureTable.Cell>
        <FeatureTable.Cell width={cellWidths.toCellWidth}>
          <Skeleton className="h-auto justify-center">
            <Skeleton.Row>
              <Skeleton.Line className="h-[22px] w-full" />
            </Skeleton.Row>
          </Skeleton>
        </FeatureTable.Cell>
        <FeatureTable.Cell width={cellWidths.timestampCellWidth}>
          <Skeleton className="h-auto justify-center">
            <Skeleton.Row>
              <Skeleton.Line className="h-[22px] w-full" />
            </Skeleton.Row>
          </Skeleton>
        </FeatureTable.Cell>
      </>
    )
  },
)
