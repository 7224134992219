import type { _FragmentRefs } from "relay-runtime"
import { AssetEvent } from "@/features/activity/types"
import { isDealEventType } from "@/features/deals/constants"
import { AssetEventEventType } from "@/lib/graphql/__generated__/EventHistory_data.graphql"
import type { utilsAssetEventLabel$data } from "@/lib/graphql/__generated__/utilsAssetEventLabel.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { EventLabelData } from "../constants"

const readAssetEventLabel = inlineFragmentize<utilsAssetEventLabel$data>(
  graphql`
    fragment utilsAssetEventLabel on AssetEventType @inline {
      isMint
      isAirdrop
      eventType
    }
  `,
  identifiers => identifiers,
)

export const getAssetEventLabel = ({
  ref,
  MINT_LABEL,
  AIRDROP_LABEL,
  EVENT_TYPES,
}: {
  ref: utilsAssetEventLabel$data | _FragmentRefs<"utilsAssetEventLabel">
  MINT_LABEL: EventLabelData
  AIRDROP_LABEL: EventLabelData
  EVENT_TYPES: Record<AssetEventEventType, EventLabelData>
}) => {
  const assetEvent = readAssetEventLabel(ref)

  if (assetEvent.isMint) {
    return MINT_LABEL
  }

  if (assetEvent.isAirdrop) {
    return AIRDROP_LABEL
  }

  return EVENT_TYPES[assetEvent.eventType]
}

export const getAssetEventItem = (event: AssetEvent) => {
  const { eventType, item, swap } = event
  const isDeal = isDealEventType(eventType)
  return isDeal && swap ? getNodes(swap.makerAssetQuantities)[0].asset : item
}
