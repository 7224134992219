import React, { useCallback, useMemo } from "react"
import {
  CenterAligned,
  useIsLessThanLg,
  useIsLessThanXl,
  useIsLessThanXXl,
  useIsLessThanXXXl,
  Text,
} from "@opensea/ui-kit"
import localforage from "localforage"
import { LAST_CLICKED_BEST_ASK_DATA_KEY } from "@/components/assets/localstorage"
import { useSidebarContext } from "@/components/search/SidebarContext.react"
import { NO_HISTORY_DATA_IMG } from "@/constants"
import {
  FeatureTable,
  FeatureTableRenderProps,
} from "@/design-system/FeatureTable"
import { Image } from "@/design-system/Image"
import { ScrollingPaginatorProps } from "@/design-system/ScrollingPaginator"
import { useTranslate } from "@/hooks/useTranslate"
import { getItemUrl } from "@/lib/helpers/item"
import { AssetEvent } from "../types"
import { ActivityTableFullRow } from "./ActivityTableFullRow"
import { ActivityTableHeader } from "./ActivityTableHeader"
import { ActivityTableMore } from "./ActivityTableMore"
import { ActivityTableRowPrimary } from "./ActivityTableRowPrimary"
import { getAssetEventItem } from "./utils"

const NUM_SKELETONS = 16
export type ActivityTableItem = {
  assetEvent: AssetEvent | null
  index: number
  showCollectionLinks: boolean
  href: string | null
}

type ActivityTableProps = {
  assetEvents: AssetEvent[]
  showCollectionLinks?: boolean
  pagination?: Omit<ScrollingPaginatorProps, "intersectionOptions">
}

export type IActivityTableCellWidths = {
  eventLabelCellWidth: string
  itemCellWidth: string
  priceCellWidth: string
  rarityCellWidth: string
  quantityCellWidth: string
  fromCellWidth: string
  toCellWidth: string
  timestampCellWidth: string
}

export const ActivityTable = React.memo(function ActivityTable({
  assetEvents,
  showCollectionLinks = true,
  pagination,
}: ActivityTableProps) {
  const t = useTranslate("activity")
  const setLastClickedBestAskData = useCallback(
    ({ data }: Pick<FeatureTableRenderProps<ActivityTableItem>, "data">) => {
      if (!data.assetEvent) {
        return
      }
      const itemId = data.assetEvent.item?.relayId
      const bestAskEthPrice = data.assetEvent.perUnitPrice?.eth

      if (itemId && bestAskEthPrice) {
        localforage.setItem(
          LAST_CLICKED_BEST_ASK_DATA_KEY,
          `${itemId}-${bestAskEthPrice}`,
        )
      }
    },
    [],
  )

  const isMobile = useIsLessThanLg()
  const isUnderXL = useIsLessThanXl()
  const isXXL = !useIsLessThanXXl()
  const isXXXL = !useIsLessThanXXXl()
  const { sidebarCollapsed } = useSidebarContext()

  const cellWidths: IActivityTableCellWidths = useMemo(
    () => ({
      eventLabelCellWidth: "140px",
      itemCellWidth:
        isXXXL || (sidebarCollapsed && isXXL)
          ? "300px"
          : sidebarCollapsed || isXXL || (!isMobile && isUnderXL)
          ? "250px"
          : "160px",
      priceCellWidth: "100px",
      rarityCellWidth: "80px",
      quantityCellWidth: "80px",
      fromCellWidth:
        sidebarCollapsed || isXXL || (!isMobile && isUnderXL)
          ? "125px"
          : "100px",
      toCellWidth:
        sidebarCollapsed || isXXL || (!isMobile && isUnderXL)
          ? "125px"
          : "100px",
      timestampCellWidth:
        sidebarCollapsed || isXXL || (!isMobile && isUnderXL)
          ? "100px"
          : "75px",
    }),
    [isMobile, isUnderXL, isXXL, isXXXL, sidebarCollapsed],
  )

  const hasMore = pagination?.page.hasMore()
  const numSkeletons = hasMore ? NUM_SKELETONS : 0

  const getHref = (assetEvent: AssetEvent) => {
    if (!assetEvent.item) {
      return ""
    }
    return getItemUrl(assetEvent.item)
  }

  const items: ActivityTableItem[] = Array(
    pagination ? assetEvents.length + numSkeletons : NUM_SKELETONS,
  )
    .fill({})
    .map((_, index) => ({
      assetEvent: assetEvents[index] ? assetEvents[index] : null,
      index,
      showCollectionLinks,
      href: assetEvents[index] ? getHref(assetEvents[index]) : null,
    }))

  const getIsItemLoaded = useCallback(
    (index: number, items: ActivityTableItem[]) => !!items[index]?.assetEvent,
    [],
  )
  const getKey = useCallback(
    ({ assetEvent }: ActivityTableItem, index: number) =>
      assetEvent?.relayId ?? index,
    [],
  )

  const renderFullRow = useCallback(
    ({ data }: FeatureTableRenderProps<ActivityTableItem>) => (
      <ActivityTableFullRow
        cellWidths={cellWidths}
        data={data}
        itemKey={
          (data.assetEvent && getAssetEventItem(data.assetEvent)) ?? null
        }
      />
    ),
    [cellWidths],
  )

  const renderHeader = useCallback(
    () => <ActivityTableHeader cellWidths={cellWidths} />,
    [cellWidths],
  )

  if (
    assetEvents.length === 0 &&
    hasMore === false // hasMore is undefined before response of first query
  ) {
    return (
      <CenterAligned className="pb-20 pt-6 lg:pb-10">
        <Image alt="" height={100} src={NO_HISTORY_DATA_IMG} width={100} />
        <Text asChild className="text-center">
          <p>{t("table.noItems", "No event history yet")}</p>
        </Text>
      </CenterAligned>
    )
  }

  return (
    <FeatureTable
      data-testid="ActivityTable"
      fullRowMinBreakpoint="lg"
      header={renderHeader()}
      isItemLoaded={getIsItemLoaded}
      itemHeightEstimate={isMobile ? 133 : 81}
      itemKey={getKey}
      items={items}
      overscanBy={1.25}
      pagination={pagination}
      renderFullRow={renderFullRow}
      renderMore={ActivityTableMore}
      renderPrimary={ActivityTableRowPrimary}
      onClick={setLastClickedBestAskData}
    />
  )
})
