/**
 * @generated SignedSource<<79a3a02ec3ec4c5c7644f6cd6bef3968>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DealLink_swap$data = {
  readonly relayId: string;
  readonly " $fragmentType": "DealLink_swap";
};
export type DealLink_swap$key = {
  readonly " $data"?: DealLink_swap$data;
  readonly " $fragmentSpreads": FragmentRefs<"DealLink_swap">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DealLink_swap",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    }
  ],
  "type": "SwapType",
  "abstractKey": null
};

(node as any).hash = "2a6677d5a57d23c8d286a4a8bd9f0d24";

export default node;
