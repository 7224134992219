import React from "react"
import styled from "styled-components"
import { themeVariant } from "@/styles/styleUtils"

type Props = {
  className?: string
  height?: number
  width?: number
}

// The icon itself is slightly taller than it is wide so height value should be a little bigger
export const CollectionOfferIcon = ({
  height = 21,
  width = 20,
  ...rest
}: Props) => (
  <StyledSvg
    height={height}
    width={width}
    {...rest}
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.0625 0.734375C9.64844 0.460938 10.3125 0.460938 10.8984 0.734375L19.4531 4.67969C19.7656 4.83594 20 5.14844 20 5.5C20 5.89062 19.7656 6.20312 19.4531 6.35938L10.8984 10.3047C10.3125 10.5781 9.64844 10.5781 9.0625 10.3047L0.507812 6.35938C0.195312 6.20312 0 5.89062 0 5.5C0 5.14844 0.195312 4.83594 0.507812 4.67969L9.0625 0.734375ZM19.4531 9.67969C19.7656 9.83594 20 10.1484 20 10.5C20 10.8906 19.7656 11.2031 19.4531 11.3594L10.8984 15.3047C10.3125 15.5781 9.64844 15.5781 9.0625 15.3047L0.507812 11.3594C0.195312 11.2031 0 10.8906 0 10.5C0 10.1484 0.195312 9.83594 0.507812 9.67969L2.61719 8.70312L8.55469 11.4375C9.45312 11.8672 10.5078 11.8672 11.4062 11.4375L17.3438 8.70312L19.4531 9.67969ZM11.4062 16.4375L17.3438 13.7031L19.4531 14.6797C19.7656 14.8359 20 15.1484 20 15.5C20 15.8906 19.7656 16.2031 19.4531 16.3594L10.8984 20.3047C10.3125 20.5781 9.64844 20.5781 9.0625 20.3047L0.507812 16.3594C0.195312 16.2031 0 15.8906 0 15.5C0 15.1484 0.195312 14.8359 0.507812 14.6797L2.61719 13.7031L8.55469 16.4375C9.45312 16.8672 10.5078 16.8672 11.4062 16.4375Z" />
  </StyledSvg>
)

const StyledSvg = styled.svg`
  ${({ theme }) =>
    themeVariant({
      variants: {
        light: {
          fill: theme.colors.oil,
        },
        dark: {
          fill: theme.colors.fog,
        },
      },
    })}
`
