import styled from "styled-components"
import { CollectionLink } from "@/components/collections/CollectionLink"
import { Link, LinkProps } from "@/components/common/Link"
import { Block, BlockProps } from "@/design-system/Block"

export const ActivityTableCollectionLink = styled(CollectionLink)`
  .CollectionLink--link {
    color: inherit;
    font-size: inherit;
    position: relative;
  }
  .CollectionLink--link.CollectionLink--isSmall {
    color: inherit;
    font-size: inherit;
  }

  &:hover .CollectionLink--link {
    color: ${props => props.theme.colors.text.primary};
  }
`

export const CoverLink = styled(Link)<BlockProps & LinkProps>`
  color: inherit;
  font-size: inherit;

  &:hover {
    color: inherit;
    font-size: inherit;
  }

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const CoverLinkContainer = styled(Block)`
  display: contents;

  a {
    position: relative;
    z-index: 1;
  }

  ${CoverLink} {
    position: static;
  }
`
