import React from "react"
import styled from "styled-components"
import { themeVariant } from "@/styles/styleUtils"

type Props = {
  className?: string
  height?: number
  width?: number
}

// The icon itself is slightly taller than it is wide so height value should be a little bigger
export const AirdropIcon = ({ height = 21, width = 20, ...rest }: Props) => (
  <StyledSvg
    height={height}
    width={width}
    {...rest}
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.625 8V13H11.875C12.1484 13 12.3828 13.0781 12.6172 13.1953L17.3047 8H16.2109C16.2109 7.80469 16.25 7.60938 16.25 7.375C16.25 4.91406 15.3516 2.64844 13.9844 1.16406C17.5781 2.45312 19.2578 5.30469 19.8047 7.29688C19.9219 7.6875 19.6094 8 19.2578 8H19.0234L13.5156 14.0547C13.6719 14.2891 13.75 14.6016 13.75 14.875V18.625C13.75 19.6797 12.8906 20.5 11.875 20.5H8.08594C7.07031 20.5 6.21094 19.6797 6.21094 18.625V14.875C6.21094 14.6016 6.28906 14.2891 6.44531 14.0547L0.9375 8H0.703125C0.351562 8 0.0390625 7.6875 0.15625 7.29688C0.703125 5.30469 2.38281 2.45312 5.97656 1.16406C4.60938 2.64844 3.75 4.91406 3.75 7.375C3.75 7.60938 3.75 7.80469 3.75 8H2.65625L7.34375 13.1953C7.57812 13.0781 7.8125 13 8.08594 13H9.33594V8H5C5 7.80469 4.96094 7.60938 4.96094 7.375C4.96094 5.38281 5.58594 3.66406 6.5625 2.41406C7.5 1.16406 8.71094 0.5 9.96094 0.5C11.25 0.5 12.4609 1.16406 13.3984 2.41406C14.375 3.66406 15 5.38281 15 7.375C15 7.60938 14.9609 7.80469 14.9609 8H10.625Z" />
  </StyledSvg>
)

const StyledSvg = styled.svg`
  ${({ theme }) =>
    themeVariant({
      variants: {
        light: {
          fill: theme.colors.oil,
        },
        dark: {
          fill: theme.colors.fog,
        },
      },
    })}
`
