import React from "react"
import { Icon, Text, FlexColumn, classNames } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { ItemCell } from "@/components/assets/ItemCell"
import { FiatPrice } from "@/components/assets/price/FiatPrice.react"
import {
  getTokenPricePayment,
  TokenPrice,
} from "@/components/assets/price/TokenPrice.react"
import { CollectionCell } from "@/components/collections/CollectionCell.react"
import { Link } from "@/components/common/Link"
import { Overflow } from "@/components/common/Overflow"
import { EventTimestamp } from "@/components/events/EventTimestamp"
import { EMPTY_PRICE_DISPLAY } from "@/constants"
import { Block } from "@/design-system/Block"
import { FeatureTable } from "@/design-system/FeatureTable"
import { Item } from "@/design-system/Item"
import { Tooltip } from "@/design-system/Tooltip"
import { PortfolioTableItemCellTooltip } from "@/features/account/components/PortfolioTable/components/tooltips/PortfolioTableItemCellTooltip.react"
import { AssetEventDealCell } from "@/features/activity/components/AssetEventDealCell"
import { AssetEventDealStatus } from "@/features/activity/components/AssetEventDealStatus.react"
import { DealLink } from "@/features/deals/components/DealLink"
import { isDealEventType } from "@/features/deals/constants"
import { useTranslate } from "@/hooks/useTranslate"
import { ActivityTableFullRow_itemData$key } from "@/lib/graphql/__generated__/ActivityTableFullRow_itemData.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { bn, MAX_DISPLAYED_DECIMAL_PLACES } from "@/lib/helpers/numberUtils"
import { AssetEventOrderStatus } from "../components/AssetEventOrderStatus.react"
import { useCopy } from "../constants"
import { ActivityTableItem, IActivityTableCellWidths } from "./ActivityTable"
import { ActivityTableFullRowSkeleton } from "./ActivityTableFullRowSkeleton"
import { ActivityTableCollectionLink, CoverLinkContainer } from "./styles"
import { getAssetEventItem, getAssetEventLabel } from "./utils"

type ActivityTableFullRowProps = {
  data: ActivityTableItem
  cellWidths: IActivityTableCellWidths
  itemKey: ActivityTableFullRow_itemData$key | null
}

export const ActivityTableFullRow = ({
  data,
  cellWidths,
  itemKey,
}: ActivityTableFullRowProps) => {
  const { assetEvent, showCollectionLinks, href } = data
  const t = useTranslate("collection")
  const eventCopy = useCopy()

  const itemData = useFragment(
    graphql`
      fragment ActivityTableFullRow_itemData on ItemType {
        __typename
        hidden
        ... on AssetType {
          isDelisted
          isListable
          isReportedSuspicious
          isCompromised
          isUnderReview
          isSuspicious
        }
        ...PortfolioTableItemCellTooltip_item
      }
    `,
    itemKey,
  )

  if (!assetEvent) {
    return <ActivityTableFullRowSkeleton cellWidths={cellWidths} />
  }

  const unlisted =
    itemData?.hidden ||
    itemData?.isDelisted ||
    itemData?.isReportedSuspicious ||
    itemData?.isCompromised ||
    itemData?.isUnderReview ||
    itemData?.isSuspicious ||
    !itemData?.isListable

  const eventLabel = getAssetEventLabel({ ref: assetEvent, ...eventCopy })

  const {
    seller,
    winnerAccount,
    orderStatus,
    perUnitPrice,
    payment,
    itemQuantity,
    sellOrder,
    eventType,
    swap,
  } = assetEvent

  const isDeal = isDealEventType(eventType)
  const item = getAssetEventItem(assetEvent)

  const fromAccount = seller || assetEvent.fromAccount
  const toAccount = winnerAccount || assetEvent.toAccount
  const tokenPricePayment = payment ? getTokenPricePayment(payment) : null
  const rarityRank = item?.defaultRarityData?.rank

  const eventLabelTableCell = (
    <FeatureTable.Cell
      justifyContent="flex-start"
      padding="0px 2px"
      width={cellWidths.eventLabelCellWidth}
    >
      {eventLabel.getIcon && eventLabel.getIcon({ height: 18, width: 17 })}
      {eventLabel.icon && <Icon size={18} value={eventLabel.icon} />}
      <FlexColumn className="w-full">
        <Text asChild className="my-0 ml-2" size="small" weight="semibold">
          <h6>
            {eventLabel.name}
            {sellOrder?.taker?.__typename && (
              <> {t("activityEventLabel.privateListing", "- Reserved")}</>
            )}
          </h6>
        </Text>

        {orderStatus ? (
          <AssetEventOrderStatus orderStatus={orderStatus} />
        ) : null}
        {isDeal && (
          <AssetEventDealStatus
            eventType={eventType}
            size="tiny"
            weight="semibold"
          />
        )}
      </FlexColumn>
    </FeatureTable.Cell>
  )

  const itemCollection =
    item?.__typename === "AssetType"
      ? item.collection
      : item?.__typename === "AssetBundleType"
      ? item.bundleCollection
      : undefined

  const showCollectionLink = showCollectionLinks && itemCollection

  const itemTableCell = (
    <FeatureTable.Cell
      justifyContent="start"
      padding="0px 2px"
      width={cellWidths.itemCellWidth}
    >
      {isDeal && swap ? (
        <AssetEventDealCell swap={swap} />
      ) : item ? (
        <ItemCell
          item={item}
          link={false}
          renderName={({ name, quantityDisplayText, className }) => (
            <FlexColumn className={classNames("items-start px-2", className)}>
              <Item.Title
                className="inline-block w-full text-left"
                data-testid="activity-table-item-name"
              >
                <PortfolioTableItemCellTooltip
                  disabled={unlisted}
                  item={itemData}
                  offset={[-16, 40]}
                  placement="bottom-start"
                >
                  <Overflow lines={1}>
                    <Link href={href ?? undefined} variant="unstyled">
                      {name}
                    </Link>
                    {quantityDisplayText}
                  </Overflow>
                </PortfolioTableItemCellTooltip>
              </Item.Title>
              {showCollectionLink && (
                <Item.Description className="text-left">
                  <Overflow breakWord lines={1}>
                    <Text className="flex text-secondary" size="tiny">
                      <ActivityTableCollectionLink
                        assetContract={item.assetContract}
                        collection={itemCollection}
                        isTiny
                      />
                    </Text>
                  </Overflow>
                </Item.Description>
              )}
            </FlexColumn>
          )}
        />
      ) : (
        <CollectionCell
          collection={assetEvent.collection}
          trait={assetEvent.traitCriteria}
        />
      )}
    </FeatureTable.Cell>
  )

  const priceTableCell = (
    <FeatureTable.Cell
      justifyContent="flex-end"
      padding="0px 2px"
      width={cellWidths.priceCellWidth}
    >
      {perUnitPrice && tokenPricePayment ? (
        <FlexColumn className="items-end">
          <Block maxWidth={cellWidths.priceCellWidth}>
            <TokenPrice
              className="relative"
              maxDecimalPlaces={MAX_DISPLAYED_DECIMAL_PLACES}
              price={bn(perUnitPrice.unit)}
              {...tokenPricePayment}
            />
          </Block>
          <Block maxWidth={cellWidths.priceCellWidth}>
            <Text className="text-secondary" size="tiny">
              <FiatPrice
                className="relative"
                color="inherit"
                price={bn(perUnitPrice.usd)}
              />
            </Text>
          </Block>
        </FlexColumn>
      ) : isDeal && swap ? (
        <Tooltip content={t("activityEventLabel.dealTooltip", "View deal")}>
          <span>
            <DealLink swap={swap} target="_blank">
              {t(
                "activityEventLabel.dealTakerItemCount",
                {
                  "0": "{{count}} items",
                  one: "{{count}} item",
                  other: "{{count}} items",
                },
                {
                  count: swap.takerAssetQuantities.count,
                },
              )}
            </DealLink>
          </span>
        </Tooltip>
      ) : (
        <FeatureTable.StatText>---</FeatureTable.StatText>
      )}
    </FeatureTable.Cell>
  )

  const rarityTableCell = (
    <FeatureTable.Cell padding="0px 2px" width={cellWidths.rarityCellWidth}>
      <FeatureTable.StatText className="w-full text-end" weight="regular">
        {rarityRank ? <Overflow>#{rarityRank}</Overflow> : EMPTY_PRICE_DISPLAY}
      </FeatureTable.StatText>
    </FeatureTable.Cell>
  )

  const quantityTableCell = (
    <FeatureTable.Cell padding="0px 2px" width={cellWidths.quantityCellWidth}>
      <FeatureTable.StatText className="w-full text-end" weight="regular">
        <Overflow>
          {isDeal ? EMPTY_PRICE_DISPLAY : itemQuantity || EMPTY_PRICE_DISPLAY}
        </Overflow>
      </FeatureTable.StatText>
    </FeatureTable.Cell>
  )

  const fromTableCell = (
    <FeatureTable.Cell padding="0px 2px" width={cellWidths.fromCellWidth}>
      {fromAccount ? (
        <AccountLink
          dataKey={fromAccount}
          fontWeight={400}
          handleOverflow
          justifyContent="end"
          overrides={{
            AccountTooltip: { width: "100%" },
            Text: { props: { textAlign: "right" } },
          }}
          variant="no-image"
        />
      ) : (
        <FeatureTable.StatText className="text-right" weight="regular">
          ---
        </FeatureTable.StatText>
      )}
    </FeatureTable.Cell>
  )

  const toTableCell = (
    <FeatureTable.Cell padding="0px 2px" width={cellWidths.toCellWidth}>
      {toAccount ? (
        <AccountLink
          dataKey={toAccount}
          fontWeight={400}
          handleOverflow
          justifyContent="end"
          overrides={{
            AccountTooltip: { width: "100%" },
            Text: { props: { textAlign: "right" } },
          }}
          variant="no-image"
        />
      ) : (
        <FeatureTable.StatText className="text-right" weight="regular">
          ---
        </FeatureTable.StatText>
      )}
    </FeatureTable.Cell>
  )

  const timestampTableCell = (
    <FeatureTable.Cell
      justifyContent="end"
      padding="0px 2px"
      width={cellWidths.timestampCellWidth}
    >
      <EventTimestamp data={assetEvent} fontWeight={400} />
    </FeatureTable.Cell>
  )
  return (
    <CoverLinkContainer>
      {eventLabelTableCell}
      {itemTableCell}
      {priceTableCell}
      {rarityTableCell}
      {quantityTableCell}
      {fromTableCell}
      {toTableCell}
      {timestampTableCell}
    </CoverLinkContainer>
  )
}
